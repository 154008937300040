import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ComponentRef, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';
import { OrderData } from '@providers/order-data.provider';
import { BookingTransactionsService } from '@services/booking-transactions.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { GoogleAuthService } from '../../../../../services/authentication/google-auth.service';
import { PopupModalsService } from '../../../../../services/popup-modals.service';
import { UserDataService } from '../../../../../services/xrm/user-data.service';
import { MainDataProvider } from './../../../../../providers/xrm/main-data.provider';
import { ConfigurationService } from '@services/configuration.service';

@Component({
    selector: 'app-login-and-register',
    templateUrl: './login-and-register.component.html',
    styleUrls: ['./login-and-register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('lrAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(40px)' }),
                animate('.3s', style({ opacity: 1, transform: 'translateY(0)' })),
            ]),
        ]),
    ],
})
export class LoginAndRegisterComponent implements OnInit, OnDestroy {

    constructor(
        // public activeModal: NgbActiveModal,
        private ref: ChangeDetectorRef,
        private _authService: AuthenticationService,
        private _googleAuth: GoogleAuthService,
        private _popupModalsService: PopupModalsService,
        private _userDataService: UserDataService,
        private _logger: NGXLogger,
        private elRef: ElementRef,
        private deviceDetectorService: DeviceDetectorService,
        public _mainDataProvider: MainDataProvider,
        public _localizationProvider: LocalizationProvider,
        public _orderData: OrderData,
        private _bookingTransactionService: BookingTransactionsService,
        private _configurationService: ConfigurationService
    ) {

    }

    private componentRef: ComponentRef<LoginAndRegisterComponent>;
    private successCallback: Function;
    private closeOnOverlayClick = false;
    public prefillData = null;

    public disableEmailAuthorization: boolean = false;

    private socialErrorShow = false;

    /**
     * The component settings
     */
    public pageSettings = {
        tabs: {
            login: {
                type: 'login',
                title: '',
                topLink: [
                    {
                        text: this._localizationProvider.getLocalizedText('signInLabel'),
                        open: 'login',
                    },
                    {
                        text: this._localizationProvider.getLocalizedText('signUpLabel'),
                        open: 'register',
                    },
                ],
                footer: {
                    description: this._localizationProvider.getLocalizedText('openRegisterFormButtonLabel2'),
                    link: this._localizationProvider.getLocalizedText('signUpLabel'),
                    open: 'register',
                },
                claimAccountLink: true,
            },
            register: {
                type: 'register',
                title: '',
                topLink: [
                    {
                        text: this._localizationProvider.getLocalizedText('signInLabel'),
                        open: 'login',
                    },
                    {
                        text: this._localizationProvider.getLocalizedText('signUpLabel'),
                        open: 'register',
                    },
                ],
                footer: {
                    description: this._localizationProvider.getLocalizedText('openLoginFormLabel'),
                    link: this._localizationProvider.getLocalizedText('signInLabel'),
                    open: 'login',
                },
                claimAccountLink: true,
            },
            resetPassword: {
                type: 'resetPassword',
                title: this._localizationProvider.getLocalizedText('resetPasswordButtonLabel'),
                // topLink: [{
                //     text: '< Back to log in',
                //     open: 'login'
                // }],
                claimAccountLink: false,
            },
            claimAccount: {
                type: 'claimAccount',
                title: this._localizationProvider.getLocalizedText('claimAccountFormTitle'),
                // topLink: [{
                //     text: '< Back to log in',
                //     open: 'login'
                // }]
                claimAccountLink: false,
            },
        },
    };

    public activeComponent: {
        type: string;
        title: string;
        topLink: { text: string; open: string }[];
        footer?: { description: string; link: string, open: string };
        claimAccountLink: boolean;
    } = this.pageSettings.tabs.login;

    public defaultOpenTab = 'register'; // possible values - 'register' | 'login' | 'resetPassword' | 'claimAccount'

    public socialLogins = [];
    public socialRegisters = [];
    private subscriptions: Subscription[] = [];
    public disableClaimAccount = false;
    public disableAuthLoginOptions = [];
    public disableAuthRegisterOptions = [];

    public claimAccountSettings = null;
    @HostListener('document:click', ['$event.target']) public clickedOutside(targetElement) {
        // Close payments dropdown when you click outside of input element
        if (targetElement && this.closeOnOverlayClick && targetElement.className.indexOf('obf-modal-overlay') !== -1) {
            this.closeModal();
        }
    }

    public ngOnInit() {

        const configurationSettings: any = this._configurationService.getConfigurationSettings();

        if (configurationSettings?.auth?.settings?.disable_auth_login_options) {
            this.disableAuthLoginOptions = configurationSettings?.auth?.settings?.disable_auth_login_options;
        }

        if (configurationSettings?.auth?.settings?.disable_auth_register_options) {
            this.disableAuthRegisterOptions = configurationSettings?.auth?.settings?.disable_auth_register_options;
        }

        if (configurationSettings?.auth?.settings?.disable_claim_account) {
            this.disableClaimAccount = configurationSettings?.auth?.settings?.disable_claim_account;
        }
        
        if (configurationSettings?.auth?.settings?.disable_email_authorization) {
            this.disableEmailAuthorization = configurationSettings.auth.settings.disable_email_authorization;
        }
        
        const bookingTransactionId = this._orderData.activeBooking.getValue()?.get('id');
        if (bookingTransactionId) {
            this._bookingTransactionService.appendTags(bookingTransactionId, { tags: ['seen_login_modal'] });
        }

        // Dynamically get default tab
        this.defaultOpenTab = this._userDataService.getLoginModalDefaultTab();

        this.claimAccountSettings = this._mainDataProvider.obfEnv.additionalConfig.claimAccount;

        // Assign texts from languages file
        Object.assign(this.claimAccountSettings, {
            title: this._localizationProvider.getLocalizedText('claimAccountTitle'),
            description: this._localizationProvider.getLocalizedText('claimAccountDescriptionTooltip'),
            linkButton: this._localizationProvider.getLocalizedText('claimAccountButtonLabel'),
        });

        this.activeComponent = this.pageSettings.tabs[this.defaultOpenTab];
        this.subscribeForSocialProviders();

        this.subscriptions.push(this._popupModalsService.loginAndRegisterModalChange.subscribe((modalConfig) => {
            if (modalConfig && modalConfig.data) {
                this.setModalData(modalConfig.data);
            }
        }));
    }

    public ngOnDestroy() {
        // this.socialProvidersSubscription.unsubscribe();
        this.subscriptions.map((el) => {
            el.unsubscribe();
        });
    }

    /**
     * Social providers subscription
     */
    private subscribeForSocialProviders(): void {
        this.subscriptions.push(
            this._authService.socialProviders.subscribe((data) => {
                if (data.length) {
                    // Logins
                    const dataLogins = data.filter((el) => !!el.data && this.disableAuthLoginOptions?.indexOf(el.type?.toLowerCase()) === -1);
                    this.socialLogins = JSON.parse(JSON.stringify(dataLogins));
                    
                    // Registers
                    const dataRegisters = data.filter((el) => !!el.data && this.disableAuthRegisterOptions?.indexOf(el.type?.toLowerCase()) === -1);
                    this.socialRegisters = JSON.parse(JSON.stringify(dataRegisters));
                }
            }),
        );
    }

    /**
     * Scroll to top if using bottom login/register links
     */
    public scrollTopInsideLoginModal(): void {

        let componentOffset;

        if (this.elRef.nativeElement.children && this.elRef.nativeElement.children.length) {
            for (let childIndex = 0; childIndex < this.elRef.nativeElement.children.length; childIndex++) {
                if (this.elRef.nativeElement.children[childIndex].classList.contains('obf-lr-modal')) {
                    componentOffset = this.elRef.nativeElement.children[childIndex].offsetTop;
                }
            }
        }

        // IE doesn't support scrollTo
        if (this.deviceDetectorService.getDeviceInfo().browser !== 'IE' && this.deviceDetectorService.getDeviceInfo().browser !== 'EDGE') {
            if (document.getElementsByClassName('obf-lr-modal')[0] && typeof document.getElementsByClassName('obf-lr-modal')[0].scrollTo === 'function') {
                setTimeout(() => {
                    document.getElementsByClassName('obf-lr-modal')[0].scrollTo({
                        top: componentOffset,
                        behavior: 'smooth',
                    });
                }, 100);
            }
        }
    }
    /**
     * Change tab in view
     * @param activeModule
     */
    public changeActiveModule(activeModule?: any): void {

        this.scrollTopInsideLoginModal();

        if (this.activeComponent.type === 'register' || this.activeComponent.type === 'resetPassword') {
            this.activeComponent = this.pageSettings.tabs.login;
        } else {
            this.activeComponent = this.pageSettings.tabs.register;
        }
    }

    /**
     * Change view
     * @param event
     */
    public changeView(event: any): void {
        if (event) {
            if (event.tab && event.tab !== '') {
                this.activeComponent = this.pageSettings.tabs[event.tab];
                this.ref.detectChanges();

                if (event.data) {
                    this.prefillData = event.data;
                } else {
                    this.prefillData = null;
                }
            } else {
                this.prefillData = null;
            }
        }

    }

    /**
     * Sets data needed for popup visualisation
     * @param {object} modalData
     * @param {object} modalComponentRef reference to component itself
     */
    public setModalData(modalData: any): void {
        this.closeOnOverlayClick = modalData.closeOnOverlayClick ? modalData.closeOnOverlayClick : false;

        if (modalData.callback && typeof modalData.callback === 'function') {
            this.successCallback = modalData.callback;
        }

        if (modalData.openTab) {

            this.activeComponent = this.pageSettings.tabs[modalData.openTab];

            this.defaultOpenTab = modalData.openTab;
        }
    }

    /**
     * Destroy component by itself
     */
    public async closeModal(event?): Promise<any> {
        const modalConfig: any = {
            action: 'close',
        };
        const callbackBeforeClose = this.successCallback;

        this._popupModalsService.loginAndRegisterModalChange.next(modalConfig);

        // Check is preferences set.
        if (this._userDataService.getUserData() && this._userDataService.getUserData().type_id !== 1) {
            await this._userDataService.notificationPreferences();
        }

        if (event && typeof this.successCallback === 'function') {
            callbackBeforeClose(event);
        }

    }
}
